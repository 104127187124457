import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import Protected from './context/Protected';
import { AuthContextProvider } from './context/AuthContext';
import HomePage from './pages/HomePage';
import Signinpage from './pages/SignInPage';
import Signuppage from './pages/SignUpPage';
import OwnEventpage from './pages/OwnEventPage';
import JoinedEventpage from './pages/JoinedEventPage';
import Friendpage from './pages/FriendPage';
import Calendarpage from './pages/CalendarPage';
import Settingspage from './pages/SettingsPage';
import Profilepage from './pages/ProfilePage';
import Notifications from './pages/NotificationPage';
import ProfileSettingspage from './pages/ProfileSettingsPage';
import EventFormpage from './pages/EventFormPage';
import EditEventFormpage from './pages/EditEventFormPage';
import EditItemListFormpage from './pages/EditItemListFormPage';
import EventDetailpage from './pages/EventDetailPage';
import ArchivedEventpage from './pages/ArchivedEventsPage';
import ChatListpage from './pages/ChatListPage';
import PrivateChatpage from './pages/PrivateChatPage';

const App = () => {
  return (
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            <Route path='/' element={<Signinpage />} />
            <Route path='/signup' element={<Signuppage />} />
            <Route path='/OwnEventsPage' element={<Protected><OwnEventpage /></Protected>} />
            <Route path='/JoinedEventsPage' element={<Protected><JoinedEventpage /></Protected>} />
            <Route path='/ArchivedEventsPage' element={<Protected><ArchivedEventpage /></Protected>} />
            <Route path='/FriendPage' element={<Protected><Friendpage /></Protected>} />
            <Route path='/ChatListPage' element={<Protected><ChatListpage /></Protected>} />
            <Route path='/PrivatChatPage/:uid1/:uid2' element={<Protected><PrivateChatpage /></Protected>} />
            <Route path='/CalendarPage' element={<Protected><Calendarpage /></Protected>} />
            <Route path='/SettingsPage' element={<Protected><Settingspage /></Protected>} />
            <Route path='/NotificationPage' element={<Protected><Notifications /></Protected>} />
            <Route path='/ProfilePage/:uid' element={<Protected><Profilepage /></Protected>} />
            <Route path='/ProfileSettingsPage' element={<Protected><ProfileSettingspage /></Protected>} />
            <Route path='/EventFormPage' element={<Protected><EventFormpage /></Protected>} />
            <Route path='/EditItemListFormPage/:event_id' element={<Protected><EditItemListFormpage /></Protected>} />
            <Route path='/EditEventFormPage/:event_id' element={<Protected><EditEventFormpage /></Protected>} />
            <Route path='/EventPage/EventDetailPage/:event_id' element={<Protected><EventDetailpage /></Protected>} />
            <Route path='/HomePage' element={<Protected><HomePage /></Protected>} />
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
  );
};

export default App;
