// hooks/useOwnEvents.js

import { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../context/AuthContext.js';
import { getDataFromBackend, sendDataToBackend } from '../services/crudApi.js';

const useOwnEvents = () => {
  const { user } = UserAuth();
  const [ownEvents, setOwnEvents] = useState([]);
  const [friendDropDownVisible, setFriendDropDownVisible] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  useEffect(() => {
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  useEffect(() => {
    if (loading) return;

    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const target = document.querySelector('#load-more');
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMore]);

  const fetchEventData = async () => {
    try {
      const ownEventData = await getDataFromBackend(`events/${user.uid}?page=${page}`);
      if (ownEventData.events && ownEventData.events.length > 0) {
        setOwnEvents((prevEvents) => [...prevEvents, ...ownEventData.events]);
        setHasMore(ownEventData.hasMore);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching event data:", error);
      setLoading(false);
    }
  };

  const hideFriendDropDown = () => {
    setFriendDropDownVisible({});
  };

  const handleInviteClick = (eventId) => {
    setFriendDropDownVisible((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId],
    }));
  };

  const closeAndArchive = async (event_id) => {
    try {
      const updateData = {
        event_id: event_id,  
      };
      await sendDataToBackend(updateData, `events/archive`);
      setOwnEvents((prevEvents) => prevEvents.filter((event) => event.event_id !== event_id));
    } catch (error) {
      console.error("Error archiving event data:", error);
    }
  };

  return {
    ownEvents,
    friendDropDownVisible,
    loading,
    hasMore,
    observer,
    user,
    hideFriendDropDown,
    handleInviteClick,
    closeAndArchive,
  };
};

export default useOwnEvents;
