import React from 'react'
import Event from '../components/events/Event'

function EventDetailpage() {
  return (
    <div>
       <Event/>
    </div>
  )
}

export default EventDetailpage