import React from 'react';
import JoinedEvents from '../components/events/JoinedEvents';

function JoinedEventpage() {
  return (
    <div>
       <JoinedEvents />
    </div>
  )
};

export default JoinedEventpage;
