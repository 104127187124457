// src/components/FriendDropDown.js
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, FormControlLabel, Typography, Card, CardContent, CardMedia, Grid } from '@mui/material';
import { sendDataToBackend } from '../../services/crudApi';
import useFriendDropDown from '../../hooks/useFriendDropDown';
import '../../styles/friendDropDown.css'; 

const FriendDropDown = ({ event_id, onInvite, creator_uid, user }) => {
  const { friendsData, guestsData } = useFriendDropDown(event_id);
  
  const formik = useFormik({
    initialValues: {
      selectedFriends: [],
    },
    validationSchema: Yup.object({
      selectedFriends: Yup.array().min(1, 'At least one friend must be selected').required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        let invitationData = [...values.selectedFriends, user.uid];
        await sendDataToBackend(invitationData, `events/invites/${event_id}`);
        onInvite();
      } catch (error) {
        console.error("Error sending invites:", error);
      }
    },
  });

  const handleCheckboxChange = (friendId) => {
    formik.setFieldValue('selectedFriends', formik.values.selectedFriends.includes(friendId)
      ? formik.values.selectedFriends.filter(id => id !== friendId)
      : [...formik.values.selectedFriends, friendId]);
  };
  
  return (
    <div className="container">
      <Typography variant="h6" gutterBottom>
        Friend List
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {friendsData.map((friend, index) => {
            const guestUids = guestsData.guests.map(guest => guest.guest_uid);
            const isInvitedOrJoined = guestUids.includes(friend.uid);
            const isOwner = friend.uid === creator_uid;

            return (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={friend.photo_url}
                    alt={friend.username}
                  />
                  <CardContent>
                    {isOwner ? (
                      <Typography variant="body2" color="textSecondary">
                        Owner
                      </Typography>
                    ) : isInvitedOrJoined ? (
                      <Typography variant="body2" color="textSecondary">
                        Already Invited
                      </Typography>
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.selectedFriends.includes(friend.uid)}
                            onChange={() => handleCheckboxChange(friend.uid)}
                          />
                        }
                        label={friend.username}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {formik.touched.selectedFriends && formik.errors.selectedFriends ? (
          <Typography color="error" variant="body2">
            {formik.errors.selectedFriends}
          </Typography>
        ) : null}
        <Button variant='contained' color='primary' type="submit">
          Invite Selected Friends
        </Button>
      </form>
    </div>
  );
};

export default FriendDropDown;
