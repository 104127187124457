import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../ui/Searchbar.jsx';
import '../../styles/topbar.css'; 

const TopBar = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <div className="topbar">
       <h2 onClick={() => handleNavigation('/HomePage')}>
        Eventuall
      </h2>
      <SearchBar/>
    </div>
  );
};

export default TopBar;