import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { io } from 'socket.io-client';
import PocketBase from 'pocketbase';
import { sendDataToBackend } from '../services/crudApi'; // Ensure this function is available for backend communication

const socketURL = process.env.REACT_APP_ENV === 'production' 
    ? 'wss://eventuall.de/' 
    : 'ws://localhost:5001';

const socket = io(socketURL, {
  transports: ['websocket'],
  upgrade: true,  // Ensure the connection attempts to upgrade to WebSocket
});

const AuthContext = createContext();
const pb = new PocketBase('https://eventuallpocketbase.de');

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  
  const credentialSignIn = async (user) => {
    try {
      // Create a new user object with renamed id to uid
      const userWithUid = {
        ...user.record,
        uid: user.record.id, // Rename id to uid
      };

      // Send user data to the backend
      sendDataToBackend(userWithUid, 'user');

      // Update user state
      setUser(userWithUid);
    } catch (error) {
      console.log('OAuth error:', error);
    }
  };

  const googleSignIn = async () => {
    try {
      // Start OAuth2 authentication with Google
      await pb.collection('users').authWithOAuth2({ provider: 'google' });
      const originalUser = pb.authStore.model; // Access user data from authStore

      // Create a new user object with renamed id to uid
      const userWithUid = {
        ...originalUser,
        uid: originalUser.id, // Rename id to uid
      };

      // Send user data to the backend
      sendDataToBackend(userWithUid, 'user');

      // Update user state
      setUser(userWithUid);
    } catch (error) {
      console.log('OAuth error:', error);
    }
  };

  const logOut = () => {
    if (user) {
      socket.emit('leaveRoom', user.uid); // Emit leave room event using uid
    }
    pb.authStore.clear(); // Clear PocketBase session
    setUser(null);
  };

  useEffect(() => {
    // Check if the user is already authenticated
    if (pb.authStore.isValid) {
      const originalUser = pb.authStore.model;

      // Create a new user object with renamed id to uid
      const userWithUid = {
        ...originalUser,
        uid: originalUser.id, // Rename id to uid
      };
      
      // Optionally, remove the original id property if you want to avoid confusion
      delete userWithUid.id;

      // Update user state only if different
      if (userWithUid.uid !== user?.uid) {
        setUser(userWithUid);

        // Join the notification room if not already joined
        if (!hasJoinedRoom) {
          socket.emit('joinNotificationRoom', { room_id: userWithUid.uid, user_uid: userWithUid.uid });
          setHasJoinedRoom(true);
        }
      }
    }

    return () => {
      if (user) {
        socket.emit('leaveRoom', user.uid);
        setHasJoinedRoom(false);
      }
    };
  }, [user, hasJoinedRoom]);

  const contextValue = useMemo(() => ({
    googleSignIn,
    credentialSignIn,
    logOut,
    user,
    socket,
    // eslint-disable-next-line
  }), [user]); 

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
