import React from 'react'
import Settings from '../components/pages/Settings'

function SettingsPage() {
  return (
    <div>
        <Settings />
    </div>
  )
}

export default SettingsPage