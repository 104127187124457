import React from 'react';
import { UserAuth } from '../../context/AuthContext.js';
import { Link } from 'react-router-dom'; 
import BaseLayout from '../shared/BaseLayout.jsx';
import useChats from '../../hooks/useChats.js';
import '../../styles/chatlist.css';

const Chats = () => {
    const { user } = UserAuth();
    const { chats, loading, error } = useChats(user?.uid);

    if (loading) return <p>Loading chats...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <BaseLayout>
            <div className="chat-container">
                <h2>Chat List</h2>
                {chats.length > 0 ? (
                    <ul className="chat-list">
                        {chats.map((chat) => (
                            <Link 
                                key={`${chat.type}-${chat.uid}-${chat.timestamp.getTime()}`} 
                                to={chat.type === 'event' ? `/EventPage/EventDetailPage/${chat.id}` : `/PrivatChatPage/${user.uid}/${chat.uid}`} 
                                className="chat-link"
                            >
                                <li className="chat-item">
                                    <div className="chat-info">
                                        <span className="chat-name">
                                            {chat.type === 'event' ? chat.event_name : chat.username}
                                        </span>
                                        <span className="chat-message">{chat.type === 'event' ? `${chat.username}:` : ''} {chat.text}</span>
                                    </div>
                                    <span className="chat-timestamp">{chat.timestamp.toLocaleString()}</span>
                                </li>
                            </Link>
                        ))}
                    </ul>
                ) : (
                    <p>No chats available.</p>
                )}
            </div>
        </BaseLayout>
    );
};

export default Chats;
