import React from 'react';
import { UserAuth } from '../../context/AuthContext.js';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BaseLayout from '../shared/BaseLayout.jsx';
import FriendDropDown from '../ui/FriendDropDown.jsx';
import LikeDislikeButton from '../ui/LikeDislikeButton.jsx';
import useJoinedEvents from '../../hooks/useJoinedEvents.js'; 
import { formatLocalDateTime } from '../../utils/DateUtils.js';
import '../../styles/eventpost.css';

const JoinedEvents = () => {
  const { user } = UserAuth();
  const {
    joinedEvents,
    showFriendDropDown,
    setShowFriendDropDown,
    creatorUid,
    loading,
    hasMore,
    observer,
    leaveEvent,
    hideFriendDropDown,
  } = useJoinedEvents(); 
  const navigate = useNavigate();

  if (loading && joinedEvents.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <BaseLayout>
      <div>
        <div className="event-container">
          <div className="column">
            <h2>Joined Events</h2>
            {joinedEvents.length > 0 ? (
              <ul className="events" id="next-events">
                {joinedEvents.map((event, index) => (
                  <li key={index}>
                    <div className="event-card">
                      <div className="card-info">
                        <div className="event-info">
                          {event.event_datetime <= new Date().toISOString() && 'Event is ongoing'}
                          <h1>
                            <span
                              onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)}
                              className="event-link"
                            >
                              {event.event_name}
                            </span>
                            <LikeDislikeButton eventData={{ ...event, user }} />
                          </h1>
                          <p>Date: {formatLocalDateTime(event.event_datetime).slice(0, 10)}</p>
                          <p>Time: {formatLocalDateTime(event.event_datetime).slice(11, 17)}</p>
                          <p>Location: {event.location}</p>
                          <p>Status: {event.event_status}</p>
                        </div>
                      </div>
                    </div>
                    <div className="button-container">
                      <Button variant="contained" onClick={() => leaveEvent(event.event_id)}>
                        Leave Event
                      </Button>
                      {event.event_status.includes('open') && (
                        <Button
                          variant="contained"
                          onClick={() => setShowFriendDropDown((prev) => (prev === event.event_id ? null : event.event_id))}
                          disabled={event.current_guests_count >= event.max_guests_count}
                        >
                          Invite Friends
                        </Button>
                      )}
                    </div>
                    {showFriendDropDown === event.event_id && (
                      <FriendDropDown event_id={event.event_id} onInvite={hideFriendDropDown} creator_uid={creatorUid} />
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No events found.</p>
            )}
          </div>
        </div>
        {hasMore && <div id="load-more" style={{ height: '1px' }} ref={observer}></div>}
      </div>
    </BaseLayout>
  );
};

export default JoinedEvents;
