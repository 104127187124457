import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const RoleDropdown = ({ currentRole, onRoleChange, disabled }) => {
    const handleChange = (event) => {
        onRoleChange(event.target.value);
    };

    return (
        <FormControl variant="outlined">
            <InputLabel>Role</InputLabel>
            <Select
                value={currentRole}
                onChange={handleChange}
                disabled={disabled} 
                label="Role" 
            >
                <MenuItem value="editor">Editor</MenuItem>
                <MenuItem value="viewer">Viewer</MenuItem>
                <MenuItem value="owner">Owner</MenuItem>
            </Select>
        </FormControl>
    );
};

export default RoleDropdown;
