import { useEffect, useState } from 'react';
import { getDataFromBackend } from '../services/crudApi.js';

const useChats = (userId) => {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId) fetchChats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const fetchChats = async () => {
        try {
            setLoading(true);
            const chatsData = await getDataFromBackend(`all-messages/${userId}`);
            
            if (chatsData) {
                const combinedChats = [
                    ...chatsData.eventChats.map(chat => ({
                        type: 'event',
                        id: chat.event_id,
                        text: chat.text,
                        timestamp: new Date(chat.timestamp),
                        username: chat.username,
                        uid: chat.uid,
                        event_name: chat.event_name
                    })),
                    ...chatsData.privateChats.map(chat => {
                        const otherUserUsername = chat.sender_uid === userId ? chat.receiver_username : chat.sender_username;
                        return {
                            type: 'private',
                            text: chat.text,
                            timestamp: new Date(chat.timestamp),
                            username: otherUserUsername,
                            uid: chat.sender_uid === userId ? chat.receiver_uid : chat.sender_uid
                        };
                    })
                ];
                combinedChats.sort((a, b) => b.timestamp - a.timestamp);
                setChats(combinedChats);
            } else {
                throw new Error('Failed to retrieve chats');
            }
        } catch (err) {
            console.error('Error fetching chats:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { chats, loading, error };
};

export default useChats;
