import React from 'react'
import SignIn from '../components/pages/SignIn'

function SignInpage() {
  return (
    <div>
        <SignIn />
    </div>
  )
}

export default SignInpage