import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserSearch from '../ui/UserSearch.jsx';
import BaseLayout from '../shared/BaseLayout.jsx';
import useFriendsData from '../../hooks/useFriends.js'; // Adjust the path as necessary
import '../../styles/friendlist.css'; 

const Friends = () => {
  const navigate = useNavigate(); 
  const { friendsData, error } = useFriendsData();

  if (error) {
    return <div>Error fetching friends data</div>;
  }

  return (
    <BaseLayout>
      <div>
        <UserSearch/>
        <div className="container">
          <h2>Friend List</h2>
          <div className="friend-list">
            <div className="row">    
              {friendsData.map((friend, index) => (
                <div className="col-md-4 col-sm-6" key={index}>
                  <div className="friend-card">
                    <div className="card-info">
                      <div className="friend-info">                                 
                        <h5>
                          <span 
                            onClick={() => navigate(`/profilepage/${friend.uid}`)} 
                            className="profile-link"
                          >
                            {friend.username}
                          </span>
                        </h5> 
                        <img src={friend.photo_url} alt={friend.username} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Friends; 
