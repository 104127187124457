import React from 'react';
import Friends from '../components/pages/Friends';

function Friendpage() {
  return (
    <div>
       <Friends />
    </div>
  )
};

export default Friendpage;
