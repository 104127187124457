import React, { useEffect, useState } from 'react';
import { UserAuth } from '../../context/AuthContext.js';
import { getDataFromBackend, updateDataInDb } from '../../services/crudApi.js';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import '../../styles/friendrequest.css';

const FriendRequests = () => {
    const { user } = UserAuth();
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate(); 

    useEffect(() => {
        fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]); 

    const fetchUserData = async () => {
        try {
            const friendRequests = await getDataFromBackend(`users/friendrequests/${user.uid}`);
            setUserData(friendRequests);              
        } catch (error) {
            console.error("Error fetching friend request data:", error);
        }
    };

    const acceptFriendRequest = async (targetUserUid) => {
        let updateData = {
            status: 'accepted',
            uid_transmitter: targetUserUid,
            uid_receiver: user.uid
        };
        await updateDataInDb(updateData, `users/update/friendrequests`);
        setUserData(prevData => prevData.filter(request => request.uid !== targetUserUid));
    };
    
    const rejectFriendRequest = async (targetUserUid) => {
        let updateData = {
            status: 'declined',
            uid_transmitter: targetUserUid,
            uid_receiver: user.uid
        };
        await updateDataInDb(updateData, `users/update/friendrequests`);
        setUserData(prevData => prevData.filter(request => request.uid !== targetUserUid));
    };

    return (
        <form className="notifications-form">
            <div className="container">
                <div className="friend-list" id="friend-requests">
                    <div className="row">
                        {userData.map((user, index) => (
                            <div className="col-md-4 col-sm-6" key={index}>
                                <div className="friend-card">
                                    <div className="card-info">
                                        <div className="friend-info">   
                                            <h5>
                                                <span 
                                                    onClick={() => navigate(`/ProfilePage/${user.uid}`)} 
                                                    className="profile-link"
                                                >
                                                    {user.username}
                                                </span> 
                                                wants to be your friend
                                            </h5> 
                                            <img src={user.photo_url} alt={user.username} />
                                            <Button variant="contained" className="btn btn-primary pull-right" onClick={() => rejectFriendRequest(user.uid)}>Reject</Button>
                                            <Button variant="contained" className="btn btn-primary pull-right" onClick={() => acceptFriendRequest(user.uid)}>Accept</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FriendRequests;
