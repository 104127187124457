// src/hooks/useEventChat.js
import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

const useEventChat = (event_id, uid) => {
  const socketRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const messagesEndRef = useRef(null);
  const chatSocketURL = process.env.REACT_APP_ENV === 'production'
    ? 'wss://www.eventuall.de/'
    : 'ws://localhost:5000';

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(chatSocketURL, {
      transports: ['websocket'],
      upgrade: true,
    });

    // Join the event room when the component mounts
    socketRef.current.emit("joinEventRoom", event_id);

    // Listen for previous messages for the specific event
    socketRef.current.on("previousMessages", (previousMessages) => {
      setMessages(previousMessages);
    });

    // Listen for new messages specific to the event
    socketRef.current.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      // Cleanup event listeners and disconnect the socket when the component unmounts
      socketRef.current.off("message");
      socketRef.current.off("previousMessages");
      socketRef.current.emit("leaveRoom", event_id);
      socketRef.current.disconnect();
    };
  }, [event_id, chatSocketURL]);

  const sendMessage = () => {
    if (messageInput.trim() !== "" && uid && event_id) {
      const message = { event_id, uid, text: messageInput, timestamp: new Date() };
      socketRef.current.emit("message", message);
      setMessageInput("");
    }
  };

  return {
    messages,
    messageInput,
    setMessageInput,
    sendMessage,
    messagesEndRef,
  };
};

export default useEventChat;
