import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'; 
import { updateDataInDb,getDataFromBackend,deleteDataFromBackend } from '../../services/crudApi.js';
import { UserAuth } from '../../context/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatLocalDateTime } from '../../utils/DateUtils.js'; 
import { Button } from '@mui/material';
import BaseLayout from '../shared/BaseLayout.jsx';
import '../../styles/forms.css';

const EditEventForm = () => {
    const { user } = UserAuth();
    const { event_id } = useParams(); 
    const [selectedFile, setSelectedFile] = useState(null);
    const [, setIsDeleteButtonClicked] = useState(false);
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(false);
    const [data, setData] = useState([]);
    const [guestsData, setGuestsData] = useState({ guests: [] });
    const isCreator = data[0]?.creator_uid === user?.uid;
    const isOwner = guestsData.guests.some(guest => guest.guest_uid === user?.uid && guest.role === "owner");
    const buildValidationSchema = (data) => {
        const baseSchema = {
          eventName: Yup.string().required('Event name is required'),
          location: Yup.string().required('Location is required'),
          description: Yup.string().required('Description is required'),
          maxGuests: Yup.number()
            .required('Max guests is required')
            .min(0, 'Min value is 0')
            .max(69, 'Max value is 69'),
          eventType: Yup.string().required('Event type is required'),
        };
      
        if (data[0]?.event_datetime > new Date().toISOString()) {
          baseSchema.eventDate = Yup.date()
            .required('Event date is required')
            .test('is-future-date', 'Event date cannot be in the past', (value) => {
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              return value && new Date(value).getTime() >= currentDate.getTime();
            });
      
          baseSchema.eventTime = Yup.string()
            .required('Event time is required')
            .test(
              'is-future-time',
              'Event time must be in the future for today\'s date',
              function (value) {
                const { eventDate } = this.parent;
                if (eventDate) {
                  const selectedDate = new Date(eventDate);
                  const selectedTime = value.split(':');
                  selectedDate.setHours(selectedTime[0], selectedTime[1]);
      
                  const currentDate = new Date();
                  if (selectedDate.toDateString() === currentDate.toDateString()) {
                    return selectedDate.getTime() > currentDate.getTime();
                  }
                  return true;
                }
                return true;
              }
            );
        }
      
        return Yup.object().shape(baseSchema);
    };
    const validationSchema = buildValidationSchema(data);
    const formik = useFormik({
        initialValues: {
        eventName: '',
        location: '',
        eventDate: '',
        eventTime: '',
        description: '',
        maxGuests: '',
        eventType: '',
        eventStatusPublic: false,
        eventStatusOpen: false
        },
        validationSchema: validationSchema,
        enableReinitialize: true, 
        onSubmit: async values => {
            try {
                if (user) {
                    const localDateTime = new Date(`${values.eventDate}T${values.eventTime}`);
                    const utcDateTime = localDateTime.toISOString().slice(0, 19).replace('T', ' ');
                    const eventStatus = [
                        values.eventStatusPublic,
                        values.eventStatusOpen
                    ].filter(status => status).join(',');
                    const updatedData = [
                        values.eventName,
                        values.location,
                        utcDateTime, 
                        values.description,
                        values.maxGuests,
                        values.eventType,
                        selectedFile ? selectedFile.name : '',
                        eventStatus,
                        user.uid,
                        event_id
                    ];
                    await updateDataInDb(JSON.stringify(updatedData),'events/edit/update'); 
                    setTimeout(() => navigate(`/EventPage/EventDetailPage/${event_id}`), 1000);
                } else {
                    console.log("Event not found!");
                }
            } catch (error) {
                console.error('Error updating Event data:', error);
            }
        },
    });
    const formikRef = useRef(formik);
    const handleDeleteEvent = async () => { 
        try {
            await deleteDataFromBackend(`events/edit/delete/${event_id}`); 
            setIsDeleteButtonClicked(true);
            setTimeout(() => {
                setIsDeleteButtonClicked(false);
                setRedirect(true);
            }, 1000);     
        } catch (error) {
            console.error('Error deleting Event data:', error);
        }
    };  
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    useEffect(() => {
        formikRef.current = formik;
    }, [formik]);

    useEffect(() => {
        const fetchData = async () => {
            try {             
                const data = await getDataFromBackend(`events/edit/${event_id}`);
                const guests = await getDataFromBackend(`events/guests/${event_id}`);
                setGuestsData(guests || { guests: [] }); 
                setData(data);
                formikRef.current.setValues({
                    eventName: data[0]?.event_name || '',
                    location: data[0]?.location || '',
                    eventDate: formatLocalDateTime(data[0]?.event_datetime || '').split(',')[0].split('.').reverse().join('-'),
                    eventTime: formatLocalDateTime(data[0]?.event_datetime || '').split(',')[1].trim(),
                    description: data[0]?.description || '',
                    maxGuests: data[0]?.max_guests_count || '',
                    eventType: data[0]?.event_type || '',
                    eventImage: data[0]?.image_url || '',
                    eventStatusPublic: (data[0]?.event_status ||'').split(',')[0],
                    eventStatusOpen: (data[0]?.event_status || '').split(',')[1]
                });                                  
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [user, event_id]);

    useEffect(() => {
        if (redirect) {
            navigate('/OwnEventsPage');
        }
    }, [redirect, navigate]);

    return (
        <BaseLayout>
            <form className="event-form" onSubmit={formik.handleSubmit}>
            <label htmlFor="eventName">Event Name</label>
            <input
                type="text"
                id="eventName"
                name="eventName"
                placeholder="Enter Event Name"
                value={formik.values.eventName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            {formik.touched.eventName && formik.errors.eventName ? (
                <div className="error">{formik.errors.eventName}</div>
            ) : null}

            {(isOwner || isCreator) && (
                <>
                    <label htmlFor="location">Location</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Enter Location"
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
             
                    {formik.touched.location && formik.errors.location ? (
                        <div className="error">{formik.errors.location}</div>
                    ) : null}
          
                    <label htmlFor="eventDate">Event Date</label>
                    <input
                        type="date"
                        id="eventDate"
                        name="eventDate"
                        placeholder="Select Event Date"
                        value={formik.values.eventDate}
                        onChange={formik.handleChange}
                        
                        onBlur={formik.handleBlur}
                    />

                     {formik.touched.eventDate && formik.errors.eventDate ? (
                        <div className="error">{formik.errors.eventDate}</div>
                    ) : null}
          
                    <label htmlFor="eventTime">Event Time</label>
                    <input
                        type="time"
                        id="eventTime"
                        name="eventTime"
                        placeholder="Select Event Time"
                        value={formik.values.eventTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.eventTime && formik.errors.eventTime ? (
                        <div className="error">{formik.errors.eventTime}</div>
                    ) : null}
                </>
            )}

            <label htmlFor="description">Event Description</label>
            <textarea
                id="description"
                name="description"
                placeholder="Enter Event Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.description && formik.errors.description ? (
                <div className="error">{formik.errors.description}</div>
            ) : null}

            {(isCreator) && (
                <>      
                    <label htmlFor="maxGuests">Max Guests</label>
                    <input
                        type="number"
                        id="maxGuests"
                        name="maxGuests"
                        placeholder="Enter Max Guests"
                        value={formik.values.maxGuests}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.maxGuests && formik.errors.maxGuests ? (
                        <div className="error">{formik.errors.maxGuests}</div>
                    ) : null}
                </>
            )}

            <label htmlFor="eventType">Event Type</label>
            <select
            id="eventType"
            name="eventType"
            value={formik.values.eventType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            >
            <option value="">Select Event Type</option>
            <option value="conference">Conference</option>
            <option value="workshop">Workshop</option>
            <option value="webinar">Webinar</option>
            <option value="meetup">Meetup</option>
            {/* Add more event types as needed */}
            </select>
            {formik.touched.eventType && formik.errors.eventType ? (
            <div className="error">{formik.errors.eventType}</div>
            ) : null}

            <label htmlFor="eventImage">Event Image</label>
            <div
            className="image-upload"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            >
            <input
                type="file"
                id="eventImage"
                name="eventImage"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <Button variant='contained'
                type="button"
                onClick={() => document.getElementById('eventImage').click()}
            >
                {selectedFile ? selectedFile.name : 'Choose Event Image'}
            </Button>
            <p>Drag & drop an image here or click to select</p>
        </div>

        {(isOwner || isCreator) && (
            <>
                <div className="mt-3">
                    <Button variant='contained'
                        type="button"
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            formik.setFieldValue(
                                'eventStatusPublic',
                                formik.values.eventStatusPublic === 'public' ? 'private' : 'public'
                            );
                        }}
                    >
                        {formik.values.eventStatusPublic === 'public' ? 'Make Private' : 'Make Public'}
                    </Button>
                </div>

                <div className="mt-3">
                    <Button variant='contained'
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => {
                        formik.setFieldValue(
                            'eventStatusOpen',
                            formik.values.eventStatusOpen === 'open' ? 'closed' : 'open'
                        );
                    }}
                    >
                        {formik.values.eventStatusOpen === 'open' ? 'Close Event' : 'Open Event'}
                    </Button>
                </div>
            </>
        )}
        
        <div className="mt-5 text-center">
                <Button variant='contained' type="submit" className="save-event button">
                Update Event
                </Button>

                {(isCreator) && (
                    <>  
                        <Button variant='contained'type="button" className="delete-event button" onClick={handleDeleteEvent}>
                            Delete Event
                        </Button>
                    </>
                )}
        </div>
    </form>
  </BaseLayout>
  );
};

export default EditEventForm;
