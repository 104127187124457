import React from 'react';
import PrivateChat from '../components/messages/PrivateChat';

function PrivateChatpage() {
  return (
    <div>
       <PrivateChat />
    </div>
  )
};

export default PrivateChatpage;
