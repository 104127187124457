// src/hooks/usePublicEventsData.js

import { useEffect, useState, useRef } from 'react';
import { getDataFromBackend } from '../services/crudApi.js'; // Adjust the path as necessary
import { UserAuth } from '../context/AuthContext.js'; // Adjust the path as necessary

const useHome = () => {
  const { user } = UserAuth();
  const [publicEvents, setPublicEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        if (user) {
          const publicEventsData = await getDataFromBackend(`public/events?page=${page}`);

          if (publicEventsData.events && publicEventsData.events.length > 0) {
            setPublicEvents(prevEvents => [...prevEvents, ...publicEventsData.events]);
            setHasMore(publicEventsData.hasMore);
          } else {
            setHasMore(false);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
        setLoading(false);
      }
    };

    fetchEventData();
  }, [user, page]);

  useEffect(() => {
    if (loading) return;

    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const target = document.querySelector('#load-more');
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMore]);

  return { publicEvents, loading, hasMore ,user};
};

export default useHome;
