import React from 'react';
import BaseLayout from '../shared/BaseLayout.jsx';
import useHome from '../../hooks/useHome.js'; 
import EventPost from '../events/Eventpost.jsx'; 
import Sidebar from '../shared/Sidebar.jsx'
import '../../styles/home.css';

const Home = () => {
  const { publicEvents, loading, hasMore, user } = useHome(); 

  if (loading && publicEvents.length === 0) {
    return <p>Loading...</p>; 
  }

  return (
    <BaseLayout>
      <h2>Public Events</h2>
      <div className="home">
        <Sidebar/>
        <EventPost publicEvents={publicEvents} user={user} /> 
      </div>
      {loading && <p>Loading more events...</p>}
      {hasMore && <div id="load-more" style={{ height: '1px' }}></div>}
    </BaseLayout>
  );
}

export default Home;
