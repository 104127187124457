import React from 'react';
import Home from '../components/pages/Home';

function Homepage() {
  return (
    <div>
        <Home/>
    </div>
  )
};

export default Homepage;