import React from 'react';
import Notifications from '../components/notifications/Notifications';

function Notificationpage() {
  return (
    <div>
        <Notifications/>
    </div>
  )
};

export default Notificationpage;