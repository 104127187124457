import { useState, useEffect, useCallback } from 'react';
import { getDataFromBackend } from '../services/crudApi';
import { formatLocalDateTime } from '../utils/DateUtils';
import { UserAuth } from '../context/AuthContext';

const useCalendar = () => {
  const { user } = UserAuth();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [days, setDays] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsForCurrentMonth, setEventsForCurrentMonth] = useState({});

  useEffect(() => {
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const daysInMonth = Array.from({ length: lastDayOfMonth.getDate() }, (_, i) => new Date(year, month, i + 1));
    setDays(daysInMonth);
  }, [currentMonth]);

  const fetchEventData = async () => {
    try {
      const response = await getDataFromBackend(`calendar/${user.uid}`);
      const formattedEvents = response.events?.map(event => ({
        event_date: formatLocalDateTime(event.event_datetime).slice(0, 10),
        event_name: event.event_name,
        event_id: event.event_id,
      })) || [];
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const getEventsForDate = useCallback((date) => {
    const formattedDate = formatLocalDateTime(date.toISOString()).slice(0, 10);
    return events.filter(event => event.event_date === formattedDate);
  }, [events]);

  useEffect(() => {
    const eventsMap = {};
    days.forEach(day => {
      const formattedDate = formatLocalDateTime(day.toISOString()).slice(0, 10);
      eventsMap[formattedDate] = getEventsForDate(day);
    });
    setEventsForCurrentMonth(eventsMap);
  }, [days, getEventsForDate]);

  const handlePrevMonth = () => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  const handleNextMonth = () => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  const handleToday = () => setCurrentMonth(new Date());

  return {
    selectedDate,
    setSelectedDate,
    currentMonth,
    days,
    eventsForCurrentMonth,
    isToday,
    handlePrevMonth,
    handleNextMonth,
    handleToday,
  };
};

export default useCalendar;
