import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { formatLocalDateTime } from '../../utils/DateUtils.js'; 
import BaseLayout from '../shared/BaseLayout.jsx';
import useProfile from '../../hooks/useProfile.js'; // Import the custom hook
import Button from '@mui/material/Button';
import '../../styles/profile.css';

const Profile = () => {
  const { uid } = useParams(); 
  const navigate = useNavigate();
  const [redirect] = useState(false);
  
  const {
    userData,
    isCurrentUser,
    isRequestPending,
    user,
    isFriend,
    handleSendMessage,
    handleSendFriendRequest,
    removeFriend,
  } = useProfile(uid);

  useEffect(() => {
    if (redirect) {
      setTimeout(() => window.location.reload(), 1000);    
    }
  }, [redirect]);

  return (
    <BaseLayout>
      <div className="container">
        {userData ? (
          <div id="user-profile-2" className="user-profile">
            <div className="tabbable">  
              <div className="tab-content no-border padding-24">
                <div id="home" className="tab-pane in active">
                  <div className="row">
                    <div className="col-xs-12 col-sm-3 center">
                      <span className="profile-picture">
                        <img className="editable img-responsive" src={userData?.[0]?.photo_url} alt={userData?.[0]?.username} id="avatar2"/>
                      </span> 
                      {!isCurrentUser && (isFriend(userData[0].uid) ? (
                        <div>
                          <Button variant='contained' style={{ backgroundColor: 'red' }} onClick={() => removeFriend(userData[0].uid, user.uid)}>
                            <span>Remove Friend</span>
                          </Button>
                          <Button variant='contained' onClick={() => handleSendMessage(user.uid, userData[0].uid)}>
                            Send a message
                          </Button>
                        </div>
                      ) : (
                        <div>      
                          <div className="space space-4"></div>        
                          <Button variant='contained' style={{ backgroundColor: isRequestPending ? 'orange' : 'green' }} onClick={() => handleSendFriendRequest(userData[0].uid)}>
                            <span>{isRequestPending ? 'Pending' : 'Add as a friend'}</span>
                          </Button>       
                          <Button variant='contained' onClick={() => handleSendMessage(userData[0].uid, user.uid)}>
                            Send a message
                          </Button>
                        </div>                  
                      ))}
                    </div>       
                    <div className="col-xs-12 col-sm-9">
                      <h4 className="blue">
                        <span className="middle">INFORMATION</span>
                      </h4>
                      <div className="profile-user-info">
                        <div className="profile-info-row">
                          <div className="profile-info-name"> Username: {userData?.[0]?.username} </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-info-name"> Location: {userData?.[0]?.country} / {userData?.[0]?.region} </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-info-name"> Date of Birth: {formatLocalDateTime(userData?.[0]?.date_of_birth).slice(0,10)} </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-info-name"> Joined: {formatLocalDateTime(userData?.[0].created_at).slice(0,10)} </div>
                        </div>
                      </div>
                      <div className="hr hr-8 dotted"></div>
                    </div>
                  </div>
                  <div className="space-20"></div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <div className="widget-box transparent">
                        <div className="widget-header widget-header-small">
                          <h4 className="widget-title smaller">
                            <i className="ace-icon fa fa-check-square-o bigger-110"></i>
                            <span>About {userData?.[0]?.username}</span>
                          </h4>
                        </div>
                        <div className="widget-body">
                          <div className="widget-main">
                            <span>{userData?.[0]?.description}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
        {isCurrentUser && (
          <div>
            <Button variant='contained' onClick={() => navigate(`/ProfileSettingsPage`)}>Edit Profile</Button>      
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default Profile;

