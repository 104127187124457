import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { formatLocalDateTime } from '../../utils/DateUtils';
import BaseLayout from '../shared/BaseLayout';
import useCalendar from '../../hooks/useCalendar';
import '../../styles/calendar.css';

const Calendar = () => {
  const navigate = useNavigate();
  const {
    selectedDate,
    setSelectedDate,
    currentMonth,
    days,
    eventsForCurrentMonth,
    isToday,
    handlePrevMonth,
    handleNextMonth,
    handleToday,
  } = useCalendar();

  return (
    <BaseLayout>
      <div className='calendar'>
        <div className='calendar-header'>
          <h1>{currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}</h1>
        </div>
        <div className='calendar-grid'>
          {days.map(date => {
            const formattedDate = formatLocalDateTime(date.toISOString()).slice(0, 10);
            const eventsForDate = eventsForCurrentMonth[formattedDate] || [];
            return (
              <div
                key={date.toISOString()}
                className={`calendar-cell ${isToday(date) ? 'today' : ''} ${selectedDate === formattedDate ? 'selected' : ''}`}
                onClick={() => setSelectedDate(formattedDate)}
              >
                <time dateTime={formattedDate}>{date.getDate()}</time>
                <div className="event-names">
                  {eventsForDate.map(event => (
                    <div key={event.event_id} className="event-name">
                      <h5>
                        <span 
                          onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)} 
                          className="event-link" 
                          style={{ cursor: 'pointer' }}
                        >
                          {event.event_name}
                        </span>
                      </h5>
                    </div>                
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <div className='calendar-navigation'>
          <Button variant='contained' className='date-button' onClick={handlePrevMonth}>&lt;</Button>
          <Button variant='contained' onClick={handleToday}>Today</Button>
          <Button variant='contained' onClick={handleNextMonth}>&gt;</Button>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Calendar;
