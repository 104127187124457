import React from 'react';
import ArchivedEvents from '../components/events/ArchivedEvents';

function ArchivedEventpage() {
  return (
    <div>
       <ArchivedEvents />
    </div>
  )
};

export default ArchivedEventpage;
