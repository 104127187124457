import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { formatLocalDateTime } from '../../utils/DateUtils';
import BaseLayout from '../shared/BaseLayout';
import useArchivedEvents from '../../hooks/useArchivedEvents';
import '../../styles/eventpost.css';

const ArchivedEvents = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { archivedEvents, loading, hasMore } = useArchivedEvents(user);

  if (loading && archivedEvents.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <BaseLayout>
      <div>
        <div className="event-container">
          <div className="column">
            <h2>Archived Events</h2>
            {archivedEvents.length > 0 ? (
              <ul className="events" id="next-events">
                {archivedEvents.map((event, index) => (
                  <li key={index}>
                    <div className="event-card">
                      <div className="card-info">
                        <div className="event-info">
                          <h1>
                            <span
                              onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)}
                              className="event-link"
                              style={{ cursor: 'pointer' }}
                            >
                              {event.event_name}
                            </span>
                          </h1>
                          <p>Date: {formatLocalDateTime(event.event_datetime).slice(0, 10)}</p>
                          <p>Time: {formatLocalDateTime(event.event_datetime).slice(11, 17)}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No events found.</p>
            )}
          </div>
        </div>
        {hasMore && <div id="load-more" style={{ height: '1px' }}></div>}
      </div>
    </BaseLayout>
  );
};

export default ArchivedEvents;
