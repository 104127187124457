import React, { useState } from 'react';
import { FiSearch } from "react-icons/fi";
import '../../styles/searchbar.css';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    onSearch(query); // Call the onSearch function passed as a prop
  };

  return (
    <form onSubmit={handleSubmit} className="search-bar">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search events..."
        className="search-input"
      />
      <button type="submit" className="search-button">
        <FiSearch />
      </button>
    </form>
  );
};

export default SearchBar;
