import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

const usePrivateChat = (uid1, uid2, socket, chatSocketURL) => {
  const [messages, setMessages] = useState([]);
  const chatSocketRef = useRef(null);

  useEffect(() => {
    // Initialize chat socket connection
    chatSocketRef.current = io(chatSocketURL, {
      transports: ["websocket"],
      upgrade: true, // Ensure the connection attempts to upgrade to WebSocket
    });

    // Join the private room when the component mounts
    chatSocketRef.current.emit("joinPrivateRoom", { uid1, uid2 });

    // Load previous private messages when joining the room
    chatSocketRef.current.on("previousPrivateMessages", (previousMessages) => {
      setMessages(previousMessages);
    });

    // Listen for incoming private messages
    chatSocketRef.current.on("privateMessage", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      // Cleanup event listeners and socket when the component unmounts
      chatSocketRef.current.off("privateMessage");
      chatSocketRef.current.off("previousPrivateMessages");
      chatSocketRef.current.emit("leavePrivateRoom", { uid1, uid2 });
      chatSocketRef.current.disconnect();
    };
  }, [uid1, uid2, chatSocketURL]);

  const sendMessage = (message) => {
    // Emit the private message on the chat socket
    chatSocketRef.current.emit("privateMessage", message);
  };

  return { messages, sendMessage };
};

export default usePrivateChat;
