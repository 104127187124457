import React from 'react';
import Calender from '../components/calendar/Calendar';

function Calendarpage() {
  return (
    <div>
       <Calender />
    </div>
  )
};

export default Calendarpage;
