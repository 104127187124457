import React, { useState, useEffect } from 'react';
import { getDataFromBackend, updateDataInDb, sendDataToBackend } from '../../services/crudApi.js';
import { UserAuth } from '../../context/AuthContext.js';
import { useParams, useNavigate } from 'react-router-dom';
import FriendDropDown from '../ui/FriendDropDown.jsx';
import { formatLocalDateTime } from '../../utils/DateUtils.js'; 
import ItemList from '../forms/ItemList.jsx';
import Button from '@mui/material/Button';
import RoleDropdown from '../ui/RoleDropDown.jsx'; 
import LikeDislikeButton from '../ui/LikeDislikeButton.jsx';
import Eventchat from '../messages/EventChat.jsx';
import BaseLayout from '../shared/BaseLayout.jsx';
import '../../styles/eventDetailView.css';

const Event = () => {
    const { event_id } = useParams(); 
    const { user } = UserAuth();
    const [eventData, setEventData] = useState({});
    const [, setOwnEvents] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [guestsData, setGuestsData] = useState({ guests: [] });
    const [dateTime, setDateTime] = useState('');
    const [showFriendDropDown, setShowFriendDropDown] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null); 
    const isCreator = eventData.creator_uid === user?.uid;
    const isOwner = guestsData.guests.some(guest => guest.guest_uid === user?.uid && guest.role === "owner");
    const isEditor = guestsData.guests.some(guest => guest.guest_uid === user?.uid && guest.role === "editor");
    const isViewer = guestsData.guests.some(guest => guest.guest_uid === user?.uid && guest.role === "viewer");
    const isArchived = eventData.event_status === "archived";
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, event_id]);

    useEffect(() => {
        if (redirect) {
           window.location.reload();    
        }
    }, [redirect]);

    const fetchEventData = async () => {
        try {           
            const eventData = await getDataFromBackend(`events/eventDetail/${event_id}`);
            const guests = await getDataFromBackend(`events/joinedGuests/${event_id}`);
            setDateTime(formatLocalDateTime(eventData.event_datetime));
            setEventData(eventData);
            setGuestsData(guests || { guests: [] });          
        } catch (error) {
            console.error("Error fetching event data:", error);
        }
        finally {
            setIsLoading(false); 
        }
    };

    const joinPublicEvent = async () => {
        const updateData = {
            event_id: event_id,
            uid_guest: user.uid
        };
        await updateDataInDb(updateData, `join/public/event`);
        setRedirect(true);
    };

    const leaveEvent = async () => {
        const updateData = {
            status: 'left',
            event_id: event_id,
            uid_guest: user.uid
        };
    
        try {
            await updateDataInDb(updateData, `events/userStatus/update`);
            setRedirect(true);
        } catch (error) {
            console.error('Error leaving event:', error);
        }
    };

    const removeUser = async (guest_uid) => {
        const updateData = {
            status: 'removed',
            event_id: event_id,
            uid_guest: guest_uid
        };
    
        try {
            await updateDataInDb(updateData, `events/userStatus/update`);
            setRedirect(true);
        } catch (error) {
            console.error('Error leaving event:', error);
        }
    };

    const hideFriendDropDown = async () => {
        try {
            if (user) {
                const ownEventData = await getDataFromBackend(`events/${user.uid}`);
                setOwnEvents(ownEventData ? [ownEventData] : []);
                setShowFriendDropDown(false);  
            }
        } catch (error) {
            console.error("Error fetching own event data:", error);
        }
    };

    const handleRoleChange = async (guestUid, newRole) => {
        try {
            const updateData = {
                event_id: eventData.event_id,
                guest_uid: guestUid,
                role: newRole
            };
            await updateDataInDb(updateData, `events/updateGuestRole`);
            setGuestsData(prevState => ({
                ...prevState,
                guests: prevState.guests.map(guest => 
                    guest.guest_uid === guestUid ? { ...guest, role: newRole } : guest
                )
            }));
        } catch (error) {
            console.error('Error updating guest role:', error);
        }
    };

    const closeAndArchieve = (event_id) => async () => {
        try {
          if (user) {
            const updateData = {
                event_id: event_id,  
            };
            await sendDataToBackend(updateData, `events/archive`);
            await fetchEventData();
          }
        } catch (error) {
          console.error("Error archive event data:", error);
        }
    };

    const handleSendMessage = (uid1, uid2) => {
        navigate(`/PrivatChatPage/${uid1}/${uid2}`);
    };

    return (
        <BaseLayout>
            <div className="event-detail-container">
                <div className="event-detail-info">
                    <div className='event-text'>
                        <h1>    
                            {eventData.event_name} 
                            <LikeDislikeButton eventData={{ ...eventData, user }} />
                        </h1> 
                        <p>Creator: <span 
                                        onClick={() => navigate(`/profilepage/${eventData.creator_uid}`)} 
                                        className="event-link" 
                                        style={{ cursor: 'pointer' }} 
                                        >  
                                        {eventData.creator_username}
                                    </span>  
                        </p>
                        <p>Date: {dateTime.slice(0, 10)}</p>
                        <p>Time: {dateTime.slice(11, 17)}</p>
                        <p>Location: {eventData.location}</p>
                        <p>Description: {eventData.description}</p>
                        <p>Max Guests: {eventData.max_guests_count}</p>
                        <p>Current Guests: {eventData.current_guests_count}</p>
                        <p>Invited: {eventData.invited_guests_count}</p>
                        <p>Status: {eventData.event_status}</p>
                        <p>Type: {eventData.event_type}</p>
                        <p>Image: {eventData.image_url}</p>
                    </div>
                </div> 

                <div>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            {isCreator ? (         
                                <div className="event-chat">
                                    <Eventchat event_id={event_id} uid={user.uid}/>
                                </div>    
                            ) : (
                                <div className="event-chat">                  
                                    {guestsData && guestsData.guests && guestsData.guests.length > 0 ? (
                                        guestsData.guests.some(guest => guest.guest_uid === user.uid) ? (                                       
                                            <Eventchat event_id={event_id} uid={user.uid}/>
                                        ) : (
                                            <p>No access to chat.</p> 
                                        )
                                    ) : (
                                        <p>Chat not available.</p> 
                                    )}
                                </div> 
                            )}
                        </>
                    )}
                </div>

                {isOwner || isCreator ? (
                    <>
                        <div className="button-container">                   
                            <Button variant='contained' onClick={() => navigate(`/EditEventFormPage/${eventData.event_id}`)}>Edit Event</Button>      
                            <Button 
                                variant='contained'
                                onClick={() => {
                                    setShowFriendDropDown(!showFriendDropDown);
                                    setSelectedEventId(eventData.event_id);
                                }}
                                disabled={isArchived || eventData.current_guests_count >= eventData.max_guests_count}
                            >
                                Invite Friends
                            </Button>
                            <Button variant='contained' onClick={() => navigate(`/EditItemListFormPage/${eventData.event_id}`)} disabled={isArchived}>Edit Item List</Button>
                            {isOwner && (
                                <Button variant='contained' onClick={() => leaveEvent()} disabled={isArchived}>Leave Event</Button>  
                            )}
                            <Button variant='contained' onClick={closeAndArchieve(eventData.event_id)} disabled={isArchived}>Close and Archive </Button>    
                        </div>
                        <div className='Itemlist'>
                            <ItemList event_id={eventData.event_id}/>
                        </div>
                        <div className="Guestlist">
                            <h2>Guestlist</h2>
                            <div className="row">                        
                                {guestsData.guests.length === 0 ? (
                                    <p>No guests joined yet.</p>
                                ) : (
                                    guestsData.guests.map((guest, index) => (
                                        <div className="col-md-4 col-sm-6" key={index}>
                                            <div className="guest-card">
                                                <div className="card-info">
                                                    <div className="guest-info">                                  
                                                        <h5>
                                                            {guest.guest_uid === user.uid ? (                                                   
                                                                <span onClick={() => navigate(`/profilepage/${guest.guest_uid}`)} 
                                                                    className="profile-link" 
                                                                    style={{ cursor: 'pointer' }} 
                                                                    >  
                                                                    You
                                                                </span>  
                                                            ) : (
                                                                <span onClick={() => navigate(`/profilepage/${guest.guest_uid}`)} 
                                                                    className="profile-link" 
                                                                    style={{ cursor: 'pointer' }} 
                                                                    >  
                                                                    {guest.username}
                                                                </span>                                                        
                                                            )}
                                                        </h5> 
                                                        <img src={guest.photo_url} alt={guest.username} />
                                                    </div>
                                                    {guest.guest_uid !== user.uid && (
                                                        <>
                                                                <Button
                                                                    variant='contained'
                                                                    onClick={() => handleSendMessage(user.uid, guest.guest_uid)}
                                                                        >
                                                                            Send Message
                                                                </Button>   
                                                            <Button variant='contained' onClick={() => removeUser(guest.guest_uid)} disabled={isArchived}>Remove</Button>
                                                        </>                                             
                                                    )}
                                                    <RoleDropdown
                                                        currentRole={guest.role}
                                                        onRoleChange={(newRole) => handleRoleChange(guest.guest_uid, newRole)}
                                                        disabled={isArchived} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </>
                ) : isEditor ? (
                    <>
                        <div className="button-container">                 
                            <Button variant='contained' onClick={() => navigate(`/EditEventFormPage/${eventData.event_id}`)} disabled={isArchived}>Edit Event</Button>
                            {eventData.event_status.includes('open') && (
                                <Button 
                                    variant='contained' 
                                    onClick={() => setShowFriendDropDown(!showFriendDropDown)}
                                    disabled={isArchived || eventData.current_guests_count >= eventData.max_guests_count}
                                >
                                    Invite Friends
                                </Button>
                            )}
                            <Button variant='contained' onClick={() => navigate(`/EditItemListFormPage/${eventData.event_id}`)} disabled={isArchived}>Edit Item List</Button>
                            <Button variant='contained' onClick={() => leaveEvent()} disabled={isArchived}>Leave Event</Button> 
                        </div>
                        <div className='Itemlist'>
                            <ItemList event_id={eventData.event_id} isArchived/>
                        </div>
                        <div className="Guestlist">
                            <h2>Guestlist</h2>
                            <div className="row">    
                                {guestsData.guests.map((guest, index) => (
                                    <div className="col-md-4 col-sm-6" key={index}>
                                        <div className="guest-card">
                                            <div className="card-info">
                                                <div className="guest-info">                                
                                                    <h5>
                                                        {guest.guest_uid === user.uid ? (                                                   
                                                            <span onClick={() => navigate(`/profilepage/${guest.guest_uid}`)} 
                                                                className="profile-link" 
                                                                style={{ cursor: 'pointer' }} 
                                                                >  
                                                                You
                                                            </span>  
                                                        ) : (
                                                            <span onClick={() => navigate(`/profilepage/${guest.guest_uid}`)} 
                                                                className="profile-link" 
                                                                style={{ cursor: 'pointer' }} 
                                                                >  
                                                                {guest.username}
                                                            </span>                                                        
                                                        )}
                                                    </h5> 
                                                    <img src={guest.photo_url} alt={guest.username} />
                                                </div>
                                                {guest.guest_uid !== user.uid && (
                                                <Button
                                                        variant='contained'
                                                        onClick={() => handleSendMessage(user.uid, guest.guest_uid)}
                                                            >
                                                                Send Message
                                                    </Button>                                         
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                ) : isViewer ? (
                    <>
                        <div className="button-container">         
                            <Button variant='contained' onClick={() => leaveEvent()} disabled={isArchived}>Leave Event</Button>
                            {eventData.event_status.includes('open') && (
                                <Button variant='contained' onClick={() => setShowFriendDropDown(!showFriendDropDown)}
                                    disabled={isArchived || eventData.current_guests_count >= eventData.max_guests_count}
                                >
                                    Invite Friends
                                </Button>
                            )}
                        </div>
                        <ItemList event_id={eventData.event_id} disabled={isArchived}/>
                        <div className="container">
                            <h2>Guestlist</h2>
                            <div className="guest-list">
                                <div className="row">    
                                    {guestsData.guests.map((guest, index) => (
                                        <div className="col-md-4 col-sm-6" key={index}>
                                            <div className="guest-card">
                                                <div className="card-info">
                                                    <div className="guest-info">                                
                                                        <h5>
                                                            {guest.guest_uid === user.uid ? (                                                   
                                                                <span onClick={() => navigate(`/profilepage/${guest.guest_uid}`)} 
                                                                    className="profile-link" 
                                                                    style={{ cursor: 'pointer' }} 
                                                                    >  
                                                                    You
                                                                </span>  
                                                            ) : (
                                                                <span onClick={() => navigate(`/profilepage/${guest.guest_uid}`)} 
                                                                    className="profile-link" 
                                                                    style={{ cursor: 'pointer' }} 
                                                                    >  
                                                                    {guest.username}
                                                                </span>                                                        
                                                            )}
                                                        </h5> 
                                                        <img src={guest.photo_url} alt={guest.username} />
                                                    </div>
                                                    {guest.guest_uid !== user.uid && (
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => handleSendMessage(user.uid, guest.guest_uid)}
                                                                >
                                                                    Send Message
                                                        </Button>   
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <Button variant="contained" onClick={joinPublicEvent} disabled={isArchived}>Join</Button>
                )}
                {showFriendDropDown && <FriendDropDown event_id={selectedEventId} onInvite={hideFriendDropDown} creator_uid={eventData.creator_uid} />}       
            </div>
        </BaseLayout>
    );
};

export default Event;
