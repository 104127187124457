import { useState, useEffect, useRef } from 'react';
import { getDataFromBackend } from '../services/crudApi';

const useArchivedEvents = (user) => {
  const [archivedEvents, setArchivedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  useEffect(() => {
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  useEffect(() => {
    if (loading) return;

    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const target = document.querySelector('#load-more');
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMore]);

  const fetchEventData = async () => {
    try {
      const archivedEventData = await getDataFromBackend(`events/archived/${user.uid}?page=${page}&limit=10`);
      if (archivedEventData.events && archivedEventData.events.length > 0) {
        setArchivedEvents((prevEvents) => [...prevEvents, ...archivedEventData.events]);
        setHasMore(archivedEventData.hasMore);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event data:', error);
      setLoading(false);
    }
  };

  return { archivedEvents, loading, hasMore };
};

export default useArchivedEvents;
