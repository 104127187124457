import React from 'react'
import SignUp from '../components/pages/SignUp'

function SignUppage() {
  return (
    <div>
        <SignUp />
    </div>
  )
}

export default SignUppage