import React from 'react'
import EditItemList from '../components/forms/EditItemList'

function EditItemListFormpage() {
  return (
    <div>
       <EditItemList/>
    </div>
  )
}

export default EditItemListFormpage