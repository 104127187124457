import React from 'react';
import SignInForm from '../forms/SignInForm';
import '../../styles/login.css';

const Signin = () => {
  return (
    <div className="login-container">
      <h1 className="login-title">Welcome to Eventuall</h1>
      <p className="login-subtitle">Sign in to continue</p>
      <SignInForm/> 
    </div>
  );
};

export default Signin;
