import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BaseLayout from '../shared/BaseLayout.jsx';
import FriendDropDown from '../ui/FriendDropDown.jsx';
import LikeDislikeButton from '../ui/LikeDislikeButton.jsx';
import { formatLocalDateTime } from '../../utils/DateUtils.js';
import useOwnEvents from '../../hooks/useOwnEvents.js'; 
import '../../styles/eventpost.css'; 

const OwnEvents = () => {
  const navigate = useNavigate();
  const {
    ownEvents,
    friendDropDownVisible,
    loading,
    hasMore,
    observer,
    user,
    hideFriendDropDown,
    handleInviteClick,
    closeAndArchive,
  } = useOwnEvents();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <BaseLayout>
      <div>
        <div className="event-container">
          <div className="column">
            <h2>My Events</h2>
            <Button variant='contained' className="add-event-button" onClick={() => navigate('/EventFormpage')}>
              Add Own Event
            </Button>
            {ownEvents.length > 0 ? (
              <ul className="events" id="manage-events">
                {ownEvents.map((event, index) => (
                  <li key={index}>
                    <div className="event-card">
                      <div className="card-info">
                        <div className="event-info">        
                          {event.event_datetime <= new Date().toISOString && <div className='eventStatus'>Event is ongoing</div>}                        
                          <h1>
                            <span 
                              onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)} 
                              className="event-link" 
                              style={{ cursor: 'pointer' }} 
                            >  
                              {event.event_name} 
                            </span>
                            <LikeDislikeButton eventData={{ ...event, user }} />
                          </h1>                               
                          <p>Date: {formatLocalDateTime(event.event_datetime).slice(0,10)}</p>
                          <p>Time: {formatLocalDateTime(event.event_datetime).slice(11,17)}</p>
                          <p>Location: {event.location}</p>
                          <p>Description: {event.description}</p>
                          <p>Max Guests: {event.max_guests_count}</p>
                          <p>Current Guests: {event.current_guests_count}</p>
                          <p>Invited: {event.invited_guests_count}</p>
                          <p>Status: {event.event_status}</p>
                          <p>Type: {event.event_type}</p>
                          <p>Image: {event.image_url}</p>
                        </div>
                      </div>
                    </div>
                    <div className="button-container">                   
                      <Button variant='contained' onClick={() => navigate(`/EditEventFormPage/${event.event_id}`)}>Edit Event</Button>                   
                      <Button variant='contained' onClick={() => handleInviteClick(event.event_id)}
                        disabled={event.current_guests_count >= event.max_guests_count}
                      >
                        Invite Friends
                      </Button>
                      <Button variant='contained' onClick={() => navigate(`/EditItemListFormPage/${event.event_id}`)}>Edit Item List</Button> 
                      <Button variant='contained' onClick={() => closeAndArchive(event.event_id)}>Close and Archive</Button>       
                    </div>
                    {friendDropDownVisible[event.event_id] && <FriendDropDown event_id={event.event_id} onInvite={() => hideFriendDropDown()} />}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No events found!</p>
            )}
          </div>
        </div>
        {hasMore && <div id="load-more" style={{ height: '1px' }} ref={observer}></div>}
      </div>
    </BaseLayout>
  );
};

export default OwnEvents;
