import { useEffect, useState } from 'react';
import { getDataFromBackend } from '../services/crudApi.js'; // Adjust the path as necessary
import { UserAuth } from '../context/AuthContext.js'; // Adjust the path as necessary

const useFriendsData = () => {
  const { user } = UserAuth();
  const [friendsData, setFriendsData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDataFromBackend(`users/friends/${user.uid}`);
        setFriendsData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
    };

    if (user?.uid) {
      fetchData();
    }
  }, [user]);

  return { friendsData, error };
};

export default useFriendsData;
