import React, { useEffect, useState } from 'react';
import { UserAuth } from '../../context/AuthContext.js';
import { getDataFromBackend, updateDataInDb } from '../../services/crudApi.js';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'; 
import '../../styles/eventrequest.css';

const EventRequests = () => {
    const { user } = UserAuth();
    const [eventData, setEventData] = useState([]);
    const navigate = useNavigate(); 

    useEffect(() => {
        fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]); 

    const fetchEventData = async () => {
        try {             
            const eventInvites = await getDataFromBackend(`events/invited/${user.uid}`);
            setEventData(eventInvites);                 
        } catch (error) {
            console.error("Error fetching event data:", error);
        }
    };

    const acceptEventRequest = async (targetEventId) => {
        let updateData = {
            status: 'accepted',
            event_id: targetEventId,
            uid_guest: user.uid
        };
        await updateDataInDb(updateData, `events/userStatus/update`);
        setEventData(prevData => prevData.filter(event => event.event_id !== targetEventId));
    };
    
    const rejectEventRequest = async (targetEventId) => {
        let updateData = {
            status: 'declined',
            event_id: targetEventId,
            uid_guest: user.uid
        };
        await updateDataInDb(updateData, `events/userStatus/update`);
        setEventData(prevData => prevData.filter(event => event.event_id !== targetEventId));
    };

    return (
        <form className="notifications-form">
            <div className="container">
                <div className="event-list" id="event-requests">
                    <div className="row">
                        {eventData.map((event, index) => (
                            <div className="col-md-4 col-sm-6" key={index}>
                                <div className="event-card">
                                    <div className="card-info">
                                        <div className="event-info">                                
                                            <h5>You are invited to: 
                                                <span 
                                                    onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)} 
                                                    className="event-link"
                                                >
                                                    {event.event_name}
                                                </span>
                                            </h5> 
                                            <h5>Created by 
                                                <span 
                                                    onClick={() => navigate(`/profilepage/${event.creator_uid}`)} 
                                                    className="profile-link"
                                                >
                                                    {event.creator_username}
                                                </span>
                                            </h5> 
                                            <Button 
                                                variant="contained" 
                                                className="btn btn-primary pull-right" 
                                                onClick={() => rejectEventRequest(event.event_id)}
                                            >
                                                Reject
                                            </Button>
                                            <Button 
                                                variant="contained" 
                                                className="btn btn-primary pull-right" 
                                                onClick={() => acceptEventRequest(event.event_id)}
                                            >
                                                Accept
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EventRequests;
