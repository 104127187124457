import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatLocalDateTime } from '../../utils/DateUtils.js';
import LikeDislikeButton from '../ui/LikeDislikeButton.jsx';
import Button from '@mui/material/Button';

const EventPost = ({ publicEvents, user }) => {
  const navigate = useNavigate();

  return (
    <div className="event-container">
      <div className="column">
        {publicEvents.length > 0 ? (
          <ul className="events" id="next-events">
            {publicEvents.map((event, index) => (
              <li key={index}>
                <div className="event-card">
                  <div className="card-info">
                    <div className="event-info">
                      {event.event_datetime <= new Date().toISOString() && "Event is ongoing"}
                      <h5>
                        <span 
                          onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)} 
                          className="event-link"
                        >
                          {event.event_name}
                        </span>
                      </h5>
                      <p>Creator: <span 
                          onClick={() => navigate(`/profilepage/${event.creator_uid}`)} 
                          className="event-link"
                        >
                          {event.creator_username}
                        </span>
                      </p>
                      <p>Date: {formatLocalDateTime(event.event_datetime).slice(0, 10)}</p>
                      <p>Time: {formatLocalDateTime(event.event_datetime).slice(11, 17)}</p>
                      <p>Location: {event.location}</p>
                      <p>Current Guests: {event.current_guests_count} / {event.max_guests_count}</p>
                      <p>Info: {event.description}</p>
                      <p>Type: {event.event_type}</p>
                      <p>Image: {event.image_url}</p>
                      <Button variant="contained" onClick={() => navigate(`/EventPage/EventDetailPage/${event.event_id}`)}>
                        Details
                      </Button>
                    </div>
                    <LikeDislikeButton eventData={{ ...event, user }} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No events found.</p>
        )}
      </div>
    </div>
  );
};

export default EventPost;
