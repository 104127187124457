// src/hooks/useLikeDislike.js
import { useState, useEffect } from 'react';
import { sendDataToBackend, getDataFromBackend } from '../services/crudApi';

const useLikeDislike = (eventData) => {
  const [userLikedEvents, setUserLikedEvents] = useState([]);
  const [userDislikedEvents, setUserDislikedEvents] = useState([]);

  useEffect(() => {
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData.user]);

  const fetchEventData = async () => {
    try {
      const userLikesData = await getDataFromBackend(`events/user/likes/${eventData.user.uid}`);
      const userDislikesData = await getDataFromBackend(`events/user/dislikes/${eventData.user.uid}`);
      setUserLikedEvents(userLikesData.map(like => like.event_id));
      setUserDislikedEvents(userDislikesData.map(dislike => dislike.event_id));         
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  };

  const upvote = async () => {
    try {
      const data = {
        user_uid: eventData.user.uid,
        event_id: eventData.event_id 
      };
      await sendDataToBackend(data, `events/like`);
      setUserLikedEvents(prev => [...prev, eventData.event_id]);
      setUserDislikedEvents(prev => prev.filter(id => id !== eventData.event_id)); 
    } catch (error) {
      console.error("Error liking event:", error);
    }
  };

  const downvote = async () => {
    try {
      const data = {
        user_uid: eventData.user.uid,
        event_id: eventData.event_id 
      };
      await sendDataToBackend(data, `events/dislike`);
      setUserDislikedEvents(prev => [...prev, eventData.event_id]);
      setUserLikedEvents(prev => prev.filter(id => id !== eventData.event_id)); 
    } catch (error) {
      console.error("Error disliking event:", error);
    }
  };

  return {
    userLikedEvents,
    userDislikedEvents,
    upvote,
    downvote,
  };
};

export default useLikeDislike;
