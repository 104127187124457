import React from 'react'
import EditEventForm from '../components/forms/EditEventForm'

function EditEventFormpage() {
  return (
    <div>
       <EditEventForm/>
    </div>
  )
}

export default EditEventFormpage