import React from 'react';
import OwnEvents from '../components/events/OwnEvents';

function OwnEventpage() {
  return (
    <div>
       <OwnEvents/>
    </div>
  )
};

export default OwnEventpage;
