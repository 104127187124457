// src/hooks/useFriendDropDown.js
import { useEffect, useState } from 'react';
import { getDataFromBackend } from '../services/crudApi';
import { UserAuth } from '../context/AuthContext';

const useFriendDropDown = (event_id) => {
  const { user } = UserAuth();
  const [friendsData, setFriendsData] = useState([]);
  const [guestsData, setGuestsData] = useState([]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, event_id]);

  const fetchData = async () => {
    try {
      const friends = await getDataFromBackend(`users/friends/${user.uid}`);
      const guests = await getDataFromBackend(`events/allGuests/${event_id}`);
      setFriendsData(friends);
      setGuestsData(guests || { guests: [] });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return { friendsData, guestsData, user };
};

export default useFriendDropDown;
