import React from 'react'
import BaseLayout from '../shared/BaseLayout.jsx';

function Settings() {
  return (
    <BaseLayout>
      <div>Settings</div>
    </BaseLayout>
  )
}

export default Settings