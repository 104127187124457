import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import PocketBase from 'pocketbase';
import Button from '@mui/material/Button';
import '../../styles/forms.css';

const SignUpForm = () => {
  const pb = new PocketBase('https://eventuallpocketbase.de');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const redirectToLogin = () => {
    navigate('/');
  };

  const initialValues = {
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, 'Username must be at least 3 characters')
      .required('Username is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const { username, email, password } = values;

    const data = {
      username,
      email,
      emailVisibility: false, 
      password,
      passwordConfirm: values.passwordConfirm,
    };
    
    try {
      await pb.collection('users').create(data);

      // (Optional) Send an email verification request
      //await pb.collection('users').requestVerification(email);

      navigate('/');  
    } catch (error) {
      console.error('Signup failed:', error);
      alert('Signup failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <div className="form-header"> Sign Up Form</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="input-field">
              <Field
                type="text"
                id="username"
                name="username"
                placeholder=" "
                autoComplete="username"
              />
              <label htmlFor="username">Username</label>
              <ErrorMessage
                name="username"
                component="div"
                className="error"
              />
            </div>

            <div className="input-field">
              <Field
                type="text"
                id="email"
                name="email"
                placeholder=" "
                autoComplete="email"
              />
              <label htmlFor="email">Email</label>
              <ErrorMessage
                name="email"
                component="div"
                className="error"
              />
            </div>

            <div className="input-field">
              <Field
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder=" "
                className="pswrd"
                autoComplete="current-password"
              />
              <span
                className="show"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'HIDE' : 'SHOW'}
              </span>
              <label htmlFor="password">Password</label>
              <ErrorMessage name="password" component="div" className="error" />
            </div>

            <div className="input-field">
              <Field
                type='password'
                id="passwordConfirm"
                name="passwordConfirm"
                placeholder=" "
                className="pswrd"
                autoComplete="passwordConfirm"
              />
              <label htmlFor="passwordConfirm">Confirm Password</label>
              <ErrorMessage name="passwordConfirm" component="div" className="error" />
            </div>

            <div className="button">
              <div className="inner"></div>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Signing Up...' : 'Sign Up'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="signup">
            Allready have an account?{' '}
            <Button onClick={redirectToLogin} variant="text" className="signup-button">
              Sign In
            </Button>
      </div>
    </div>
  );
};

export default SignUpForm;
