import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import PocketBase from 'pocketbase';
import useSignIn from '../../hooks/useSingIn';
import Button from '@mui/material/Button';
import '../../styles/forms.css';

const SignInForm = () => {
  const pb = new PocketBase('https://eventuallpocketbase.de');
  const { handleCredentialSignIn } = useSignIn();
  const [showPassword, setShowPassword] = useState(false);
  const { handleGoogleSignIn } = useSignIn();
  const navigate = useNavigate();
  const redirectToSignup = () => {
    navigate('/signup');
  };

  const initialValues = {
    usernameOrEmail: '',
    password: '',
  };

  const validationSchema = Yup.object({
    usernameOrEmail: Yup.string().required('Username or email is required'),
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const { usernameOrEmail, password } = values;

    try {
      const authData = await pb.collection('users').authWithPassword(
        usernameOrEmail,
        password
      );
      handleCredentialSignIn(authData);
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please check your credentials and try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <div className="form-header"> Login Form</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="input-field">
              <Field
                type="text"
                id="usernameOrEmail"
                name="usernameOrEmail"
                placeholder=" "
                autoComplete="usernameOrEmail"

              />
              <label htmlFor="usernameOrEmail">Username or Email</label>
              <ErrorMessage
                name="usernameOrEmail"
                component="div"
                className="error"
              />
            </div>

            <div className="input-field">
              <Field
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder=" "
                className="pswrd"
                autoComplete="current-password"
              />
              <span
                className="show"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'HIDE' : 'SHOW'}
              </span>
              <label htmlFor="password">Password</label>
              <ErrorMessage name="password" component="div" className="error" />
            </div>

            <div className="button">
              <div className="inner"></div>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Signing In...' : 'Sign In'}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <div className="auth">
            Or login with
      </div>

      <div className="links">
        <div className="facebook">
            <i className="fab fa-facebook-square"><span>Facebook</span></i>
        </div>
        <div className="google">
            <i className="fab fa-google-plus-square"><span onClick={handleGoogleSignIn} >Google</span></i>
        </div>
      </div>

      <div className="signup">
        Not a member?{' '}
        <Button onClick={redirectToSignup} >Signup now </Button>
      </div>
    </div>
  );
};

export default SignInForm;
