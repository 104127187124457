import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDataFromBackend, deleteDataFromBackend, sendDataToBackend } from '../../services/crudApi.js';
import { UserAuth } from '../../context/AuthContext.js';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button'; 
import BaseLayout from '../shared/BaseLayout.jsx';
import '../../styles/itemlist.css';

const EditItemList = () => {
    const { user } = UserAuth();
    const { event_id } = useParams();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({ items: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {              
                const initialData = await getDataFromBackend(`events/itemlist/${event_id}`);
                setInitialValues({ items: initialData || [] }); 
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [user, event_id]);

    const validationSchema = Yup.object({
        items: Yup.array().of(
            Yup.object({
                label: Yup.string().required('Label is required'),
                count: Yup.number().min(0, 'Count cannot be negative').required('Count is required'),
                min_count: Yup.number().min(0, 'Min count cannot be negative').required('Min count is required'),
                max_count: Yup.number().min(1, 'Max count must be at least 1').required('Max count is required')
            })
        )
    });

    const handleDeleteItem = async (label) => { 
        try {        
            await deleteDataFromBackend(`events/itemslist/delete/${label}/${event_id}`);      
        } catch (error) {
            console.error('Error deleting Item:', error); 
        }
    }; 

    const updateItemCount = (index, newCount, maxCount, setFieldValue) => {
        const boundedCount = Math.max(0, Math.min(newCount, maxCount));
        setFieldValue(`items.${index}.count`, boundedCount);
    };

    return (
        <BaseLayout>
            <div>
                <h1>Your Item List for </h1>
                <Formik
                    enableReinitialize 
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        try {                      
                            const itemData = values.items.map(item => ({
                                Label: item.label,
                                Count: item.count,
                                min_count: item.min_count,
                                max_count: item.max_count,
                            }));
                            await sendDataToBackend(itemData, `events/itemlist/edit/${event_id}`);
                            setTimeout(() => navigate(`/EventPage/EventDetailPage/${event_id}`), 1000);                           
                        } catch (error) {
                            console.error('Error updating Event data:', error);                    
                        }
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FieldArray name="items">
                                {({ insert, remove, push }) => (
                                    <div>
                                        {values.items.length > 0 &&
                                            values.items.map((item, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col">
                                                        <label htmlFor={`items.${index}.label`}>Label</label>
                                                        <Field
                                                            name={`items.${index}.label`}
                                                            placeholder="Item Label"
                                                            type="text"
                                                        />
                                                        <ErrorMessage
                                                            name={`items.${index}.label`}
                                                            component="div"
                                                            className="field-error"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label>Count</label>
                                                        <div>
                                                            <Button variant='contained'
                                                                type="button"
                                                                onClick={() => updateItemCount(index, item.count - 1, item.max_count, setFieldValue)}
                                                            >
                                                                -
                                                            </Button>
                                                            <span>{item.count}</span>
                                                            <Button variant='contained'
                                                                type="button"
                                                                onClick={() => updateItemCount(index, item.count + 1, item.max_count, setFieldValue)}
                                                            >
                                                                +
                                                            </Button>
                                                        </div>
                                                        <ErrorMessage
                                                            name={`items.${index}.count`}
                                                            component="div"
                                                            className="field-error"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label htmlFor={`items.${index}.min_count`}>Min Count</label>
                                                        <Field
                                                            name={`items.${index}.min_count`}
                                                            placeholder="Min Count"
                                                            type="number"
                                                        />
                                                        <ErrorMessage
                                                            name={`items.${index}.min_count`}
                                                            component="div"
                                                            className="field-error"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label htmlFor={`items.${index}.max_count`}>Max Count</label>
                                                        <Field
                                                            name={`items.${index}.max_count`}
                                                            placeholder="Max Count"
                                                            type="number"
                                                        />
                                                        <ErrorMessage
                                                            name={`items.${index}.max_count`}
                                                            component="div"
                                                            className="field-error"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <Button variant='contained'
                                                            type="button"
                                                            className="secondary"
                                                            onClick={() => { 
                                                                remove(index);
                                                                handleDeleteItem(item.label); 
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        <Button variant='contained'
                                            type="button"
                                            className="secondary"
                                            onClick={() => push({ label: '', count: 0, min_count: 0, max_count: 1 })}
                                        >
                                            Add Item
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                            <Button variant='contained' type="submit">Save</Button>
                        </Form>
                    )}
                </Formik>   
            </div>
        </BaseLayout>
    );
};

export default EditItemList;
