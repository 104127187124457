import React from 'react';
import FriendRequests from '../notifications/FriendRequests.jsx';
import EventRequests from '../notifications/EventRequests.jsx';
import BaseLayout from '../shared/BaseLayout.jsx';

const Notifications = () => {
    return (
    <BaseLayout>
        <div className="container">
            <FriendRequests/>
            <EventRequests/>
            <p>other Notifications coming soon</p>
        </div>
    </BaseLayout>
    );
};

export default Notifications;

