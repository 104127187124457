import { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../context/AuthContext.js';
import { getDataFromBackend, updateDataInDb } from '../services/crudApi.js';

const useJoinedEvents = () => {
  const { user } = UserAuth();
  const [joinedEvents, setJoinedEvents] = useState([]);
  const [showFriendDropDown, setShowFriendDropDown] = useState(null);
  const [creatorUid, setCreatorUid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  useEffect(() => {
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  useEffect(() => {
    if (loading) return;

    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const target = document.querySelector('#load-more');
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMore]);

  const fetchEventData = async () => {
    try {
      const joinedEventData = await getDataFromBackend(`events/joined/${user.uid}?page=${page}&limit=10`);
      const creatorUid = joinedEventData.events.length > 0 ? joinedEventData.events[0].creator_uid : null;
      if (joinedEventData.events && joinedEventData.events.length > 0) {
        setJoinedEvents((prevEvents) => [...prevEvents, ...joinedEventData.events]);
        setHasMore(joinedEventData.hasMore);
        setCreatorUid(creatorUid);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event data:', error);
      setLoading(false);
    }
  };

  const hideFriendDropDown = async () => {
    try {
      const joinedEventsData = await getDataFromBackend(`events/joined/${user.uid}?page=${page}&limit=10`);
      setJoinedEvents(joinedEventsData ? joinedEventsData.events : []);
      setShowFriendDropDown(null);
    } catch (error) {
      console.error('Error fetching joined event data:', error);
    }
  };

  const leaveEvent = async (eventId) => {
    const updateData = {
      status: 'left',
      event_id: eventId,
      uid_guest: user.uid,
    };

    try {
      await updateDataInDb(updateData, `events/userStatus/update`);
      setJoinedEvents((prevEvents) => prevEvents.filter((event) => event.event_id !== eventId));
    } catch (error) {
      console.error('Error leaving event:', error);
    }
  };

  return {
    joinedEvents,
    showFriendDropDown,
    setShowFriendDropDown,
    creatorUid,
    loading,
    hasMore,
    observer,
    leaveEvent,
    hideFriendDropDown,
  };
};

export default useJoinedEvents;
