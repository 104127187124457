// src/hooks/useUserSearch.js
import { useState, useEffect } from 'react';
import { getDataFromBackend, sendDataToBackend } from '../services/crudApi.js';
import { UserAuth } from '../context/AuthContext.js';

const useUserSearch = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [userFriends, setUserFriends] = useState([]);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const { user } = UserAuth();

  useEffect(() => {
    fetchUserFriends();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchUserFriends = async () => {
    try {
      const data = await getDataFromBackend(`users/friends/${user.uid}`);
      setUserFriends(data);
    } catch (error) {
      console.error("Error fetching user friends:", error);
    }
  };

  const searchUserInDb = async (searchTerm) => {
    try {
      const data = await getDataFromBackend(`users/search/${searchTerm}`);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const isFriend = (userId) => {
    return userFriends.some(friend => friend.uid === userId);
  };

  const handleSendFriendRequest = async (targetUserUid) => {
    try {
      const requestData = {
        senderUserUid: user.uid,
        targetUserUid: targetUserUid
      };
      setIsRequestPending(true);
      await sendDataToBackend(requestData, `users/friendrequests`);
    } catch (error) {
      console.error("Error adding friend:", error);
    } finally {
      setIsRequestPending(false);
    }
  };

  return {
    user,
    searchResults,
    setSearchResults,
    isRequestPending,
    searchUserInDb,
    isFriend,
    handleSendFriendRequest,
  };
};

export default useUserSearch;
