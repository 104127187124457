import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const useSignIn = () => {
  const { googleSignIn, credentialSignIn, user } = UserAuth();
  const navigate = useNavigate();

  const handleCredentialSignIn = async (user) => {
    try {
      await credentialSignIn(user);
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  useEffect(() => {
    if (user != null) {
      navigate('/Homepage');
    }
  }, [user, navigate]);

  return { handleGoogleSignIn, handleCredentialSignIn };
};

export default useSignIn;
