import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { FiHome, FiMessageSquare, FiUser, FiBell, FiLogOut, FiUsers, FiCalendar, FiSettings } from 'react-icons/fi';
import { LuPartyPopper } from 'react-icons/lu';
import '../../styles/sidebar.css';

const Sidebar = () => {
  const { user, logOut, socket } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [unreadCount, setUnreadCount] = useState(0);

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    if (socket) {
      socket.emit('joinNotificationRoom', { room_id: user.uid, user_uid: user.uid });

      socket.on('eventNotification', (notificationData) => {
        if (notificationData.type === 'newMessage') {
          setUnreadCount((prev) => prev + 1);
        }
      });

      return () => {
        socket.off('eventNotification');
      };
    }
  }, [user, socket]);

  return (
    <div className="sidebar">
      <div className="icon-group">
        <div onClick={() => handleNavigation('/HomePage')} className={`icon-link ${isActive('/HomePage') ? 'active' : ''}`}>
          <FiHome size={32} />
          <span className="icon-text">Home</span>
        </div>
        <div onClick={() => handleNavigation('/FriendPage')} className={`icon-link ${isActive('/FriendPage') ? 'active' : ''}`}>
          <FiUsers size={32} />
          <span className="icon-text">Friends</span>
        </div>
        <div onClick={() => handleNavigation('/ChatListPage')} className={`icon-link position-relative ${isActive('/ChatListPage') ? 'active' : ''}`}>
          <FiMessageSquare size={32} />
          <span className="icon-text">Chats</span>
          {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
        </div>
        <div className={`icon-link dropdown ${isActive('/OwnEventsPage') || isActive('/JoinedEventsPage') || isActive('/ArchivedEventsPage') ? 'active' : ''}`}>
          <LuPartyPopper size={32} />
          <span className="icon-text">Events</span>
          <div className="dropdown-content">
            <div onClick={() => handleNavigation('/OwnEventsPage')}>My Events</div>
            <div onClick={() => handleNavigation('/JoinedEventsPage')}>Joined Events</div>
            <div onClick={() => handleNavigation('/ArchivedEventsPage')}>Archived Events</div>
          </div>
        </div>
        <div onClick={() => handleNavigation('/CalendarPage')} className={`icon-link ${isActive('/CalendarPage') ? 'active' : ''}`}>
          <FiCalendar size={32} />
          <span className="icon-text">Calendar</span>
        </div>
        <div onClick={() => handleNavigation('/NotificationPage')} className={`icon-link ${isActive('/NotificationPage') ? 'active' : ''}`}>
          <FiBell size={32} />
          <span className="icon-text">Notifications</span>
        </div>
        <div onClick={() => handleNavigation(`/ProfilePage/${user?.uid}`)} className={`icon-link ${isActive(`/ProfilePage/${user?.uid}`) ? 'active' : ''}`}>
          <FiUser size={32} />
          <span className="icon-text">Profile</span>
        </div>
        <div onClick={() => handleNavigation('/SettingsPage')} className={`icon-link ${isActive('/SettingsPage') ? 'active' : ''}`}>
          <FiSettings size={32} />
          <span className="icon-text">Settings</span>
        </div>
        <div onClick={handleSignOut} className="icon-link">
          <FiLogOut size={32} />
          <span className="icon-text">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;