import { useState } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext.js";
import BaseLayout from '../shared/BaseLayout.jsx';
import usePrivateChat from "../../hooks/usePrivateChat.js"; // Import your custom hook
import useScrollToBottom from "../../hooks/useScrollToBottom.js"; // Import your scroll hook
import '../../styles/chat.css';

const PrivateChat = () => {
  const { uid1, uid2 } = useParams();
  const { socket } = UserAuth();
  const chatSocketURL = process.env.REACT_APP_ENV === 'production' 
    ? 'wss://www.eventuall.de/' 
    : 'ws://localhost:5000'; 

  // Use the custom hooks
  const { messages, sendMessage } = usePrivateChat(uid1, uid2, socket, chatSocketURL);
  const messagesEndRef = useScrollToBottom(messages);

  const [messageInput, setMessageInput] = useState("");

  const handleSendMessage = () => {
    if (messageInput.trim() !== "" && uid1 && uid2) {
      const message = {
        sender_uid: uid1,
        receiver_uid: uid2,
        text: messageInput,
        timestamp: new Date(),
      };

      // Use the sendMessage function from the custom hook
      sendMessage(message);

      // Emit a notification to the receiver on the notification socket
      socket.emit("newMessage", {
        receiver_uid: uid2,
        type: "newMessage",
      });

      setMessageInput("");
    } else {
      console.log("Message input is empty or invalid:", messageInput);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <BaseLayout>
      <div className="chat-container">
        <div className="message-container p-2 overflow-y-auto bg-gray-100 rounded-md">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message-item flex ${msg.sender_uid === uid1 ? "justify-end" : "justify-start"}`}
            >
              <div className={`message-bubble p-2 rounded-md ${msg.sender_uid === uid1 ? "bg-blue-500 text-white" : "bg-gray-300 text-black"}`} style={{ maxWidth: "60%" }}>
                {msg.text}
              </div>
              <span className="message-timestamp text-gray-500 text-xs ml-2">
                {new Date(msg.timestamp).toLocaleTimeString()}
              </span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="input-container p-2 border-t border-gray-300">
          <div className="input-wrapper flex">
            <input
              type="text"
              className="w-full px-2 py-1 border rounded-l-md outline-none"
              placeholder="Type your message..."
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PrivateChat;
