import { useEffect, useRef } from "react";

const useScrollToBottom = (messages) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return messagesEndRef;
};

export default useScrollToBottom;
