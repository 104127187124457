// src/components/LikeDislikeButton.js
import React from 'react';
import Button from '@mui/material/Button';
import useLikeDislike from '../../hooks/useLikeDislike'; // Adjust the path as needed

const LikeDislikeButton = ({ eventData }) => {
  const { userLikedEvents, userDislikedEvents, upvote, downvote } = useLikeDislike(eventData);

  return (
    <>
      <Button
        variant='contained'
        style={{ backgroundColor: 'green' }}
        onClick={upvote}
        disabled={userLikedEvents.includes(eventData.event_id)}
      >
        ⬆ {eventData.likes}
      </Button>
      <Button
        variant='contained'
        style={{ backgroundColor: 'red' }}
        onClick={downvote}
        disabled={userDislikedEvents.includes(eventData.event_id)}
      >
        ⬇ {eventData.dislikes}                      
      </Button>
    </>
  );
};

export default LikeDislikeButton;
