import { useState, useEffect } from 'react';
import { getDataFromBackend, sendDataToBackend, updateDataInDb } from '../services/crudApi.js';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext.js';

const useProfile = (uid) => {
  const { user } = UserAuth();
  const [userData, setUserData] = useState(null);
  const [userFriends, setUserFriends] = useState([]);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, user]);

  const fetchData = async () => {
    try {
      const data = await getDataFromBackend(`users/${uid}`);
      if (data) {
        setUserData(data);
        setIsCurrentUser(data[0].uid === user.uid);

        if (user && !isCurrentUser) {
          const friendsData = await getDataFromBackend(`users/friends/${user.uid}`);
          setUserFriends(friendsData);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSendMessage = (uid1, uid2) => {
    navigate(`/PrivatChatPage/${uid1}/${uid2}`);
  };

  const isFriend = (userId) => {
    return userFriends.some(friend => friend.uid === userId);
  };

  const handleSendFriendRequest = async (targetUserUid) => {
    try {
      const requestData = {
        senderUserUid: user.uid,
        targetUserUid: targetUserUid
      };
      await sendDataToBackend(requestData, `users/friendrequests`);
      setIsRequestPending(true);
    } catch (error) {
      console.error("Error adding friend:", error);
    }
  };

  const removeFriend = async (userUid, friendUid) => {
    try {
      const updateData = {
        status: 'unfriended',
        uid_transmitter: userUid,
        uid_receiver: friendUid
      };
      await updateDataInDb(updateData, `users/update/friendrequests`);
      fetchData(); // Refresh data after removing friend
    } catch (error) {
      console.error("Error removing friend:", error);
    }
  };

  return {
    userData,
    isCurrentUser,
    isRequestPending,
    user,
    isFriend,
    handleSendMessage,
    handleSendFriendRequest,
    removeFriend,
  };
};

export default useProfile;
