import React from 'react'
import ProfileSettings from '../components/forms/ProfileSettings'

const ProfileSettingspage = () => {
  return (
    <div>
      <ProfileSettings />
    </div>
  )
}

export default ProfileSettingspage