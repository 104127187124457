import React from 'react';
import Chats from '../components/messages/Chats';

function ChatListpage() {
  return (
    <div>
       <Chats />
    </div>
  )
};

export default ChatListpage;
