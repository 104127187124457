import axios from 'axios';

const baseURL = process.env.REACT_APP_ENV === 'production' 
    ? 'https://eventuall.de/api'
    : 'http://localhost:3001/api';

const sendDataToBackend = async (data, endpoint) => {
  try {
    await axios.post(`${baseURL}/${endpoint}`, data, {});
  } catch (error) {
    console.error('Error sending data to backend:', error);
  }
};
  
const updateDataInDb = async (data, endpoint) => {
  try {
    let response = await axios.put(`${baseURL}/${endpoint}`, data, {
      headers: { 'Content-Type': 'application/json' }
    });
    if (response.status !== 200 ) {
      console.error('Error updating data:', response);
    }
  } catch (error) {
    console.error('Error sending data to backend:', error);
  }
};

const getDataFromBackend = async (endpoint) => {
  try {
    let response = await axios.get(`${baseURL}/${endpoint}`);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Error retrieving data:', response);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving data from backend:', error);
    return null;
  }
};

const deleteDataFromBackend = async (endpoint) => {
  try {
    let response = await axios.delete(`${baseURL}/${endpoint}`);
    if (response.status !== 200) {
      console.error('Error deleting data:', response);
    }
  } catch (error) {
    console.error('Error deleting data from backend:', error);
  } 
};

export { sendDataToBackend, getDataFromBackend, updateDataInDb, deleteDataFromBackend };
