// src/components/Eventchat.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useEventChat from "../../hooks/useEventChat"; // Adjust the path as needed
import '../../styles/chat.css';

const Eventchat = ({ event_id, uid }) => {
  const navigate = useNavigate();
  const { messages, messageInput, setMessageInput, sendMessage, messagesEndRef } = useEventChat(event_id, uid);

  useEffect(() => {
    // Scroll to the bottom when a new message arrives
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, messagesEndRef]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage();
    }
  };

  // Function to navigate to the user's profile page
  const navigateToProfile = (userId) => {
    navigate(`/profilepage/${userId}`);
  };

  return (
    <div className="chat-container">
      <div className="message-container p-2 overflow-y-auto bg-gray-100 rounded-md">
        {messages.map((msg, index) => (
          <div key={index} className="message-item">
            <div 
              className="username font-bold text-sm text-gray-700 mb-1 cursor-pointer" 
              onClick={() => navigateToProfile(msg.uid)} 
            >
              {msg.username}
            </div>
            <div className="message-bubble bg-blue-500 text-white p-2 rounded-md">
              {msg.text}
            </div>
            <span className="message-timestamp text-gray-500 text-xs">
              {new Date(msg.timestamp).toLocaleTimeString()}
            </span>
          </div>
        ))}
        {/* Reference element to scroll to the bottom */}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container p-2 border-t border-gray-300">
        <div className="input-wrapper flex">
          <input
            type="text"
            className="w-full px-2 py-1 border rounded-l-md outline-none"
            placeholder="Type your message..."
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyDown={handleKeyDown} 
          />
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600"
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Eventchat;
