// src/components/UserSearch.js
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useUserSearch from '../../hooks/useUserSearch'; // Adjust the path as needed
import '../../styles/userlist.css';

const UserSearch = () => {
  const {
    user,
    searchResults,
    setSearchResults,
    isRequestPending,
    searchUserInDb,
    isFriend,
    handleSendFriendRequest,
  } = useUserSearch();

  const navigate = useNavigate(); 

  const formik = useFormik({
    initialValues: {
      searchTerm: ''
    },
    validationSchema: Yup.object({
      searchTerm: Yup.string()
        .required('Search term is required')
        .min(2, 'Search term must be at least 2 characters')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const results = await searchUserInDb(values.searchTerm);
        setSearchResults(results);
      } catch (error) {
        console.log('Error searching for users:', error);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                placeholder="Search for users..."
                name="searchTerm"
                value={formik.values.searchTerm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.touched.searchTerm && formik.errors.searchTerm ? 'input-error' : ''}
              />
              {formik.touched.searchTerm && formik.errors.searchTerm ? (
                <div className="error">{formik.errors.searchTerm}</div>
              ) : null}
              <Button variant='contained' type="submit" disabled={formik.isSubmitting}>Search</Button>
            </form>

            <div className="people-nearby">
              {searchResults !== null && searchResults.length > 0 ? (
                <div>
                  <h2>Search Results</h2>
                  <ul>
                    {searchResults.map((searchUser) => (
                      <div key={searchUser.uid} className="nearby-user">
                        <div className="row">
                          <div className="col-md-2 col-sm-2">
                            <img src={searchUser.photo_url} alt={searchUser.username} className="profile-photo-lg" />
                          </div>
                          <div className="col-md-7 col-sm-7">
                            <h5>
                              <span 
                                onClick={() => navigate(`/profilepage/${searchUser.uid}`)} 
                                className="profile-link" 
                                style={{ cursor: 'pointer' }} 
                              >  
                                {searchUser.username}
                              </span>
                            </h5>
                          </div>
                          <div className="col-md-3 col-sm-3">
                            {searchUser.uid !== user.uid && (
                              isFriend(searchUser.uid) ? (
                                <p>Already Friends</p>
                              ) : (
                                <Button 
                                  variant='contained' 
                                  style={{ backgroundColor: isRequestPending ? 'orange' : 'green' }} 
                                  className="btn btn-primary pull-right" 
                                  onClick={() => handleSendFriendRequest(searchUser.uid)}
                                >
                                  <span>{isRequestPending ? 'Pending' : 'Add friend'}</span>
                                </Button>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No users found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearch;

