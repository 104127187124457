import React from 'react'
import EventForm from '../components/forms/EventForm'

function EventFormpage() {
  return (
    <div>
       <EventForm/>
    </div>
  )
}

export default EventFormpage